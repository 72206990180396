<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-sheet
          color="white"
          class="pa-3"
          tile
          elevation="1"
        >
          <v-row>
            <v-col
              cols="6"
              md="2"
            >
              <v-menu
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataInicioFormatada"
                    v-mask="'##/##/####'"
                    label="Data Início"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_inicio = formatarData.api(dataInicioFormatada)"
                    @click:append="menuDataInicio = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_inicio"
                  no-title
                  class="ma-0"
                  @input="menuDataInicio = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="pl-md-0"
            >
              <v-menu
                v-model="menuDataFim"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataFimFormatada"
                    v-mask="'##/##/####'"
                    label="Data Fim"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_fim = formatarData.api(dataFimFormatada)"
                    @click:append="menuDataFim = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_fim"
                  no-title
                  class="ma-0"
                  @input="menuDataFim = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="ml-md-2 pt-0 pl-md-0 pt-md-2"
            >
              <v-btn
                color="info"
                class="mt-1"
                :loading="carregando"
                @click="carregarRelatorio()"
              >
                <v-icon
                  left
                  v-text="'mdi-magnify'"
                />
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            :loading="carregando"
            :headers="cabecalho"
            :items="linhas"
            :hide-default-footer="true"
            :items-per-page="350"
          >
            <template v-slot:[`item.data`]="{ item }">
              {{ item.data | data }}
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import relatoriosApi from '@/api/relatorios'
  import formatarData from '@/utils/formatar-data-input'
  import dayjs from 'dayjs'

  export default {

    data () {
      return {
        carregando: false,
        linhas: [],
        menuDataInicio: false,
        menuDataFim: false,
        dataInicioFormatada: null,
        dataFimFormatada: null,
        filtros: {
          data_inicio: dayjs().startOf('month').format('YYYY-MM-DD'),
          data_fim: dayjs().endOf('month').format('YYYY-MM-DD'),
        },
        formatarData,
        cabecalho: [
          { text: 'Data', value: 'data_criacao' },
          { text: 'Alertas', value: 'alertas' },
          { text: 'Leituras Corrigidas', value: 'leiturasCorrigidas' },
          { text: 'Imagens', value: 'imagens' },
          { text: 'Imagens sem OCR', value: 'imagensSemOCR' },
          { text: 'Leituras', value: 'leituras' },
          { text: 'Placas', value: 'placas' },
        ],
      }
    },

    watch: {
      'filtros.data_inicio': {
        immediate: true,
        handler (data) {
          this.dataInicioFormatada = formatarData.ptBr(data)
        },
      },
      'filtros.data_fim': {
        immediate: true,
        handler (data) {
          this.dataFimFormatada = formatarData.ptBr(data)
        },
      },
    },

    methods: {
      async carregarRelatorio () {
        try {
          this.carregando = true
          var information = []
          this.linhas = []

          const resposta = await relatoriosApi.estatisticas(this.filtros)

          resposta.data.leituras.totalLeiturasPordiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            information[item.data_criacao]['leituras'] = item.totalLeituras
          })

          resposta.data.placas.totalPlacasPordiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            information[item.data_criacao]['placas'] = item.totalPlacas
          })


          resposta.data.imagens.totalImagensPordiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            information[item.data_criacao]['imagens'] = item.totalImagens
          })

          resposta.data.imagensSemOCR.totalImagensSemOCRPorDiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            information[item.data_criacao]['imagensSemOCR'] = item.totalImagens
          })

          resposta.data.corrigidas.totalLeiturasComPlacaCorrigidaPorDiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            information[item.data_criacao]['leiturasCorrigidas'] = item.totalLeiturasCorrigidas
          })

          resposta.data.alertas.totalAlertasNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            information[item.data_criacao]['alertas'] = item.totalAlertas
          })

          Object.keys(information).forEach((key) => {
            information[key].data_criacao = dayjs(key).format('DD/MM/YYYY')
            this.linhas.push(information[key])
          })

          this.linhas.sort(
            (a, b) => new Date(b.data_criacao) - new Date(a.data_criacao),
          )
        } catch (e) {
          console.log(e)
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      }
    },

    created() {
      this.carregarRelatorio()
    }
  }
</script>
